/* Buttons container for InProgress, Completed, and Canceled buttons */
.buttons-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 1;



}




.buttond:hover {
  background-color: #000000;
}

/* Active button styling */




/* Label styling for the buttons */
.buttond-label {
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  color: #000000;

}
/* Active button (highlighted) */
.buttond.highlighted {
  background-color: black; /* Highlighted background */


}

.buttond {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin: 25px;
  background-color: #474747;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}

/* Orders list and order items */
.orders-list {
  width: 80%;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-item {
  background-color: #F4CBA5;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 100%;
  text-align: left;
}

.order-details {
  display: flex;
  flex-direction: column;
}

.order-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-icon {
  width: 35px;
  height: 35px;
}

.order-text {
  font-size: 15px;
  margin-left: 5px;
  border: 1px dashed #000000;
  padding: 5px;
}

.order-service {
  font-size: 22px;
  font-family: monospace;
}

.order-item-detail {
  font-size: 14px;
  font-family: monospace;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
}

.order-supply,
.order-package,
.order-date {
  font-size: 13px;
  margin-top: 5px;
  letter-spacing: 1px;
  font-style: italic;
  color: #000;
  margin-bottom: 5px;
}

/* Cancel button styling */




/* Review section */
.review-section {
  margin-top: 10px;
}

.rating-buttons {
  display: flex;
  gap: 1rem;
}

.rating-buttons button {
  margin-right: 10px;
}

.rating-buttons button:hover {
  background-color: #6953A4; /* Darker shade of red on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */

}

/* Cancel instructions */
.order-cancel-instructions {
  font-style: italic;
  font-size: 12px;
  color: #666;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  display: block;
  clear: both;
}

.buttonc{
  color: #ffffff;
  background-color: #b00020;
  padding: 5px;
  margin-top: 10px;
  width: 25%;
}
.buttonc:hover {
  background-color: #d32f2f; /* Darker shade of red on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}
.buttonr{
  color: #ffffff;
  background-color: #6953A4;
  padding: 5px;
  margin-top: 10px;
  width: 25%;
}
.star {
  cursor: pointer;
  color: #ffffff; /* Unselected star color */
  font-size: 24px;
}

.star-selected {
  color: #6953A4; /* Gold color for selected stars */
}

.star-unselected:hover {
  color: #6953A4; /* Gold on hover */
}



.star-disabled {
  cursor: not-allowed;
  opacity: 0.75;
  pointer-events: none; /* Prevents any interaction */

}

.star-disabled:hover {
  color: #ccc; /* No color change on hover when disabled */
}


.map-container {
  height: 400px;   /* Adjust height as needed */
  width: 100%;     /* Full width */
  margin-top: 20px;  /* Optional spacing */
}
