/* Container styling */
.settings-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.settings-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

/* Form group styling */
.settings-form .form-group {
  margin-bottom: 20px;
}

.settings-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

/* Password toggle styling */
.password-input-wrapper {
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: #000000;
}

.password-toggle-icon:hover {
  text-decoration: underline;
}

/* Button styling */
.settings-button {
  width: 100%;
  padding: 12px;
  background-color: #6953A4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.settings-button:hover {
  filter: brightness(75%);
}

/* Divider */
hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 15px 0;
}

/* Password section title */
.password-section-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}
