.invoice-card {
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-card {
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.invoice {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.service {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.settled {
  background-color: rgb(255, 255, 255);

}

.unsettled {
  background-color: #d3697d;
  color:white ;
  font-weight: bold;
}

.settle-button {
  background-color: #000000;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.settle-button:hover {
  filter: brightness(75%);
  transform: scale(1.05); /* Slight zoom effect on hover */

}
/* Container for the Add Invoice form */
.add-invoice-form {
  background-color: #f9f9f9;
  padding: 20px;
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Labels for the input fields */
.add-invoice-form label {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input fields */
.add-invoice-form input[type="text"],
.add-invoice-form input[type="number"],
.add-invoice-form input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

/* Submit button for the new invoice */
.add-invoice-form button {
  background-color: #b00020;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

}

.add-invoice-form button:hover {
  background-color: #b00020;
  transform: scale(1.05); /* Slight zoom effect on hover */

}

/* Button to add a new invoice */
.add-invoice-button {


  border: none;
font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;




  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.add-invoice-button:hover {
  background-color: #4FC3F7;
  transform: scale(1.05); /* Slight zoom effect on hover */

}

.close-button {

  color: #333;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 5px;
}

/* .close-button:hover {
  color: red;

} */

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.agent-card{
  font-size: 13px;
  margin-top: 5px;
  letter-spacing: 1px;

}

