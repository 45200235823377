/* General container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  justify-content: flex-start;


}

/* Hero Section */
/* Hero Section Styles */
.hero-section {
  background-color: #f5f5f5; /* Unified background color */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}
/* Hero Section */
.hero-intro {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;
  padding: 0 20px;
}

.hero-intro h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  color: #333;
}

.hero-intro p {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}


.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  width: 100%;
  margin-bottom: 2rem;

}

.hero-text {
  flex: 1;
  display: flex;
  flex-direction: column;

}

.hero-text h2 {
  font-size: 3rem;

  margin-bottom: 1rem; /* Reduced gap */
  color: #6953A4;
}

.services-list {
  list-style-type: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
}

.services-list li {
  position: relative; /* Position for pseudo-element */
  font-size: 1.5rem;
  margin-bottom: 1rem; /* Space between items */
  padding-left: 1.5rem; /* Space for the bullet */

}




.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hero-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: cover;
}

.hero-buttons {
  display: flex;
  flex-direction: column; /* Column direction */
  align-items: flex-start; /* Align buttons to the left */
  gap: 1rem; /* Space between buttons */
  padding-left: 20px;
}

.cta-button {
  padding: 0.75rem 1.5rem;

  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #563d8d;
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-image img {
    max-width: 100%;
    margin-top: 1.5rem;
  }

  .hero-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}



/* How It Works Section */
.how-it-works-section {
  padding: 50px 20px;
  text-align: center;
}

.how-it-works-steps {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Creates 5 equal-width columns */
  gap: 20px; /* Adds space between the columns */
  margin-top: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.step img {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.section-title {
  font-size: 22px;
  color: #000000;
  margin-bottom: 15px;
  font-weight: 700;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 5px;
}

.step p {
  font-size: 1.1rem;
  color: #333;
  margin-top: 10px;
  line-height: 1.4;
  overflow: hidden; /* Ensure text does not overflow */
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .how-it-works-steps {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    gap: 15px;
  }

  .step {
    width: 100%; /* Ensure each step takes the full width */
  }

  .step p {
    height: auto; /* Allow the text to adjust height based on content */
    overflow: visible; /* Ensure text wraps properly */
    margin-top: 5px; /* Reduce margin for better spacing */
  }
}

@media (max-width: 480px) {
  .how-it-works-steps {
    grid-template-columns: 1fr; /* Single column on very small screens */
  }

  .step img {
    width: 80px; /* Reduce image size on very small screens */
  }

  .step p {
    font-size: 1rem; /* Slightly smaller font for better fit */
  }
}



/* Services Section */
.services-section {
  display: flex;
  justify-content: center; /* Centers the entire grid horizontally */
  flex-direction: column; /* Stacks the title and grid vertically */
  align-items: center; /* Centers the title and grid container horizontally */
  width: 95%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
  justify-items: center; /* Ensures the grid items themselves are centered */

  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content inside each card */
  max-width: 500px; /* Optional: Limits the grid's width to a reasonable size */
  width: 100%;

}

/* Testimonials Section */
.testimonials-section {
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: center;
}



.testimonial span {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}


/* Card container to hold each service card */
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
 border-width: 10;

}

.card:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


/* Title inside the card */
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
}

/* Image inside the card */
.card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.promo-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #d35400;
  color: white;
  border: none;
  cursor: pointer;





  font-size: 16px;
  font-weight: bold;


  border-radius: 8px; /* Rounded corners */

  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth hover transition */
  text-align: center;
  display: inline-block; /* To ensure proper button sizing */
  margin: 10px 0; /* Margin to add some space around the button */
}

.promo-button:hover {
  filter: brightness(75%);
}

/* Order Dashboard Section */
.order-dashboard-section {
  text-align: center;
  padding: 50px 20px;
}

/* Promo Video Section */
.promo-video-section {
  text-align: center;
  padding: 50px 20px;
}

.video-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}



/* Testimonials Section */
.testimonials-section {
  padding: 50px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.testimonial {
  margin-bottom: 30px;
  font-style: italic;
}



/* Promo Section */
.promo-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #F4CBA5;
  width: 80%;
}

.promo-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #000000;
}





/* Order Dashboard Section */
.order-dashboard-section {
  text-align: center;
  padding: 50px 20px;
}

iframe {
  border: none;
}

.guarantee-section {
  text-align: center;
  padding: 40px;
   /* Or any background color you prefer */
  width: 100%;
}



.highlighted {
  color: #5865F2; /* You can adjust the color for "guaranteed" */
  font-weight: bold;
}

.guarantee-items {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.guarantee-item {
  width: 30%;
  text-align: center;
}

.guarantee-icon {
  width: 50px;
  height: auto;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.1rem;
    color: #6953A4;
    margin-bottom: 5px;
}

p {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .guarantee-items {
    flex-direction: column;
    align-items: center;
  }

  .guarantee-item {
    width: 80%;
    margin-bottom: 20px;
  }
}
/* Styling for the services section */
.service-buttons-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap; /* Allows buttons to wrap in case there are many */
  width: 100%; /* Ensure it takes up full width */
  flex-direction: row;
}

/* Styling for individual service buttons */
.service-button {

  color: #333; /* Dark text for contrast */
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
  border: 0.5px solid black; /* Black border */
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin: 10px; /* Adds space between buttons */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px; /* Set a consistent width */
  height: auto;
  text-align: center; /* Centers the text horizontally */
}

.service-button:hover {
  background-color: #e6b88c; /* Slightly darker color on hover */
}

/* Styling for the service images inside the buttons */
.service-image {
  width: 200px; /* Adjusted for consistent scaling */
  height: 200px;
  margin-bottom: 10px; /* Space between image and text */
}

/* Styling for the service text inside the buttons */
.service-text {
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  color: #000; /* Dark text */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .service-button {
    width: 250px; /* Reduce button size on smaller screens */
  }

  .service-image {
    width: 120px; /* Adjust image size for smaller screens */
    height: 120px;
  }
}

@media (max-width: 480px) {
  .service-button {
    width: 200px; /* Further reduce size for mobile screens */
  }

  .service-image {
    width: 100px; /* Smallest image size for mobile */
    height: 100px;
  }
}


/* Styling for the CTA (Call-to-Action) button */
.cta-button {
  background-color: #000000; /* Green background color */
  color: white; /* White text */
  padding: 15px 30px; /* Padding for size */
  font-size: 16px;
  font-weight: bold;
  border: none;
  text-transform: uppercase; /* Make text uppercase */
  /* border: 2px solid #6953A4; Border to match the background */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth hover transition */
  text-align: center;
  display: inline-block; /* To ensure proper button sizing */
  margin: 10px 0; /* Margin to add some space around the button */
}

/* Hover effect for the CTA button */
.cta-button:hover {

  filter: brightness(75%); /* Reduce brightness by 10% */
}
