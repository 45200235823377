
.assigned-text {
    font-size: 13px;
    margin-top: 5px;
    letter-spacing: 1px;
    font-style: italic;
    color: #000;
  }

  .rating-text {
    font-size: 13px;
    margin-top: 5px;
    letter-spacing: 1px;
    font-style: italic;
    color: #000;
  }

  .button-manage-users-orders{
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin: 5px;
    background-color: #6953A4;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  .button-manage-users-orders:hover {
    filter: brightness(75%);
  }




