.checkout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    flex-direction: column;
  }

  .checkout-card {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .checkout-title {
    text-align: right;
    font-size: 1.25rem;
    font-weight: bold;
  }

  .checkout-section {
    margin-bottom: 20px;
  }

  .checkout-section h3 {
    margin-bottom: 5px;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .checkout-summary {
    margin-top: 20px;
  }

  .checkout-summary p,
  .checkout-summary h3 {
    text-align: right;
    font-size: 1rem;
    margin: 5px 0;
  }

  .card-form {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  .card-form button {
    margin-top: 10px;
    padding: 10px;
    background-color: #6200ee;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  .card-form button:disabled {
    background-color: #ddd;
  }

  .confirm-button {
    width: 100%;
  padding: 10px;
  background-color: #6953A4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  }

  .confirm-button:hover {
    filter: brightness(75%);
        transform: scale(1.05); /* Slight zoom-in effect */
  }
  .confirm-button:disabled {
    background-color: #ddd;
  }

  .loading-indicator {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2rem;
    color:  #6953A4;
  }
