/* Container styles */
.signup-container {
  max-width: 500px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Title styles */
.signup-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

/* Error alert styles */
.signup-error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  text-align: center;
}

/* Form input styles */
.signup-form .form-group {
  margin-bottom: 20px;
}

.signup-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.password-input-wrapper {
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: #000000;
}

.password-toggle-icon:hover {
  text-decoration: underline;
}

/* Button styles */
.signup-button {
  width: 100%;
  padding: 10px;
  background-color: #6953A4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.signup-button:hover {
  filter: brightness(75%);
}

/* Loading spinner styles */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
}

/* Password instructions */
.password-instruction {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 15px;
  font-style: italic;
}
.error-message {
  color: red; /* Error message color */
  font-size: 0.9rem;
  margin-top: 5px; /* Space between the input field and error */
}
