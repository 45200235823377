.checkout-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .form-label {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
  }

  .input-field {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .card-element-container {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .card-input {
    font-size: 16px;
    color: #333;
  }

  .pay-btn {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #6772E5;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .pay-btn:hover {
    background-color: #5469d4;
  }

  .pay-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .error-message {
    color: #e74c3c;
    margin-top: 10px;
    font-size: 14px;
  }

  .dry-cleaning-summary {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .dry-cleaning-summary h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .dry-cleaning-summary ul {
    list-style-type: none;
    padding: 0;
  }

  .dry-cleaning-summary ul li {
    font-size: 16px;
    margin-bottom: 5px;
  }
