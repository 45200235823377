.career-container {
  padding: 20px;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.tabs button.active-tab {
  background-color: #007bff;
  color: white;
}

.tab-content {
  margin-top: 20px;
}

.apply-section {
  margin-top: 40px;
}


.apply-section a {
  color: #007bff;
  text-decoration: none;
}

.apply-section a:hover {
  text-decoration: underline;
}

.career-container h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.tabs button:hover {
  background-color: #d0d0d0;
}

.job-posting {
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fafafa;
  margin-bottom: 2rem;
}

.job-posting h2 {
  margin-bottom: 1rem;
}

.job-summary, .job-duties, .job-requirements, .job-offer, .job-details {
  margin-bottom: 1.5rem;
}

.job-summary h3, .job-duties h3, .job-requirements h3, .job-offer h3, .job-details h3 {
  margin-bottom: 0.5rem;
}

.job-duties ul, .job-requirements ul, .job-offer ul, .job-details ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.job-duties li, .job-requirements li, .job-offer li, .job-details li {
  margin-bottom: 0.5rem;
}