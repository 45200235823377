.terms-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
  }

  .terms-content {
    padding: 10px;
  }

  .terms-section {
    margin-bottom: 20px;
  }

  h1, h2 {
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }


