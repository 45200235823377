.help-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
  }

  .help-page-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 40px;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 1px solid #dddddd;
  }

  .help-page-title {
    color: #6953A4;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .help-page-text {
    color: #000000;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .help-page-link {
    color:#007bff;
    text-decoration: none;
  }

  .help-page-link:hover {
    text-decoration: underline;
  }
