/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the body and html take full height */
html, body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Main app container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* The main content should take available space */
.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* The content area should grow to push the footer down */
.content {
  flex: 1;
}

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f4f4f4;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Header Styles */
.header {
  background-color: #6953A4;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  color: #ffffff;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #000;
  padding: 0.5rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.mobile-menu-toggle {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav {
    display: none;
    flex-direction: column;
    gap: 0;
  }

  .nav.open {
    display: flex;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .header-content {
    justify-content: space-between;
  }

  .nav ul {
    flex-direction: column;
    gap: 0;
  }
}


.logo {
  height: 50px;
  width: auto;
  filter: invert(1) brightness(1000%);

}
.logo:hover {
  filter: brightness(75%);
  transform: scale(1.05); /* Slight zoom effect on hover */


}

/* Navbar */
.nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  font-weight: bold;
}

.nav ul li {
  position: relative;
}

.nav ul li a {
  color: #ffffff;
}

.nav ul li a:hover {
  color: #F4CBA5;
}

/* Dropdown styles */
.dropdown {
  cursor: pointer;
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #6953A4;
  border: 1px solid #ddd;
  padding: 0.5rem;
  z-index: 10;
  min-width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
}

.dropdown-menu li a {
  color: #333;
}

/* Footer Styles */
.footer {
  background-color: #6953A4;
  padding: 1.5rem 1rem;
  border-top: 1px solid #ddd;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  color: #ffffff;
}

.footer-left {
  display: flex;
  flex-direction: column;
}

.footer-links {
  margin-bottom: 1rem;
}

.footer-links a {
  margin: 0 10px;
  color: #ffffff;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-contact p {
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: medium;
  margin: 25 10px;
  padding-left: 10px;
}

.social-media {
  margin-bottom: 1rem;
}

.social-media a {
  margin: 0 10px;
  color: #ffffff;
}

.social-media a:hover {
  color: #F4CBA5;
}

/* Download Section */
.footer-right p {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.download-icon {
  width: 150px;
  height: auto;
  cursor: pointer;
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  margin-top: 0px;
  font-size: 0.9rem;
  color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-content, .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-right {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .app-tagline {
    font-size: 0.9rem;
    color: #ffffff;
    margin-top: 25px;
    display: block;
  }

  .download-buttons {
    justify-content: center;
  }

  .nav ul {
    flex-direction: column;
    gap: 1rem;
  }

  .dropdown-menu {
    min-width: 200px;
  }
}

@media (max-width: 576px) {
  .logo {
    height: 40px;
  }

  .nav ul {
    flex-direction: column;
    gap: 1rem;
  }

  .footer-content {
    text-align: center;
  }

  .footer-right {
    align-items: center;
    text-align: center;
  }

  .app-tagline {
    font-size: 1rem;
    color: #ffffff;
    margin-top: 15px;
  }

  .dropdown-menu {
    min-width: 180px;
  }

  .social-media a:hover {
    color: #000;
  }
}
