





/* Description text for guidance */
.car-description-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

/* Container for dropdowns (car brand, body style, car color) */
.car-description-dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}

/* Individual dropdown styling */
.car-description-dropdown {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
}

/* Label for the car plate input */
.car-description-plate-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

/* Input for the car plate number */
.car-description-plate-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}



/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;

}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Info button style */
.info-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
}

.info-button:hover {
  background-color: #0056b3;
}


