/* Container styles */
.verify-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  /* Title styles */
  .verify-title {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }

  /* Message and error styles */
  .verify-message {
    text-align: center;
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
  }

  .verify-error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    text-align: center;
  }

  /* Button group styles */
  .verify-button-group {
    margin-top: 20px;
    text-align: center;
  }

  /* Button styles */
  .verify-login-button {
    padding: 10px;
    background-color: #6953A4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .verify-login-button:hover {
    filter: brightness(75%);
  }
