/* .container {
    padding: 30px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    max-width: 90%; /* Ensure the container is responsive and doesn't exceed 90% width */


  .scroll-view {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the cards horizontally */
    width: 100%;
  }

  .subtotal-card {
    text-align: center;
    padding: 20px 100px;
    background-color: #83b2e2;
    border-radius: 12px;
    margin-bottom: 15px;
    box-shadow: 0 4px 15px rgba(211, 38, 38, 0.1);
    font-size: 24px;
    font-weight: bold;
    color: #333;
    transition: all 0.3s ease-in-out;
    position: sticky; /* Make it sticky */
    top: 100px; /* Stick to the top of the screen */
    z-index: 99; /* Ensure it stays on top of other elements */
    border: 0.5px solid #333; /* Border width, style, and color */


  }


  .subtotal-card:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
  }

  .card {
    background-color: #ffffff;
    padding: 20px ;
    border-radius: 12px;
    margin-bottom: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    width: 100%;
    position: relative; /* Ensure card and its content scrolls naturally */
    border: 0.5px solid #333; /* Border width, style, and color */
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Remove any sticky or fixed position */
    flex-direction: column; /* Stack the title and clear button vertically */


    width: 100%; /* Ensure the header takes full width of the card */
  }





  .card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }

  .card-content {
    font-size: 16px;
    line-height: 1.6; /* Increase line spacing for better readability */
    color: #555;

  }





  .search-input,
  .note-input,
  .datetime-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Add subtle inner shadow */
  }

  .selected-location {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }

  .wide-input {
    width: 100%; /* Ensure the input is full-width */
  }


  .item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;

  }

  .item-details {
    flex: 1;

  }

  .item-list {
    max-height: 750px; /* Limit the height to 300px, adjust as needed */
    overflow-y: auto;  /* Enable vertical scrolling */
    padding-right: 10px; /* Add some padding to prevent the scrollbar from overlapping the content */
  }
  .note-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
  }
  .item-list::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }

  .item-list::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Set the scrollbar color */
    border-radius: 4px; /* Rounded scrollbar */
  }

  .item-list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* Darken scrollbar on hover */
  }


  .item-title {

    margin: 0;
  }

  .item-price {
    white-space: nowrap; /* Prevents price from breaking into a new line */
    align-self: center; /* Ensures the price is vertically centered */
    text-decoration: none;

    text-align:left;



    color: green;

  }

  .item-actions {
    display: flex;
    align-items: center;
    margin-left: 25px; /* Pushes the item to the right */
    margin-bottom: 50px;
  }


  .qty-btn {
    width: 25px;
    height: 25px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 22px;
    cursor: pointer;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.3s ease;
  }

  .qty-btn:hover {
    background-color: #0056b3;
  }

  .item-count {
    font-size: 18px;
    color: #333;
  }

  .clear-btn {
    margin-top: 5px; /* Add some space between the title and button */
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .clear-btn:hover {
    background-color: #c82333;
  }

  .search-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
  }

  .confirm-btn {
    width: 50%;
  padding: 10px;
  background-color: #6953A4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;

  }

  .confirm-btn:hover {
    filter: brightness(75%);
    transform: translateY(-3px); /* Slight lift effect */
  }

  .service-select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .date-text {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
  }
