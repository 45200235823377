.about-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .about-section {
    margin-bottom: 40px;
  }

  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }

  /* Custom class for purple text */
.highlight-purple {
  color: #6953A4; /* Purple color */
  font-weight: bold;
}

