



  /* Items list inside the cards */
  .items-list {
    margin-left: 20px;
  }

  .items-list p {
    margin: 5px 0;
    font-family: monospace;
  }




