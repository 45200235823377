/* Container for the entire privacy policy */
.privacy-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

/* Content inside the privacy container */
.privacy-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Each section of the privacy content */
.privacy-section {
  margin-bottom: 30px;
}

/* Main heading (H1) and section headings (H2) */
h1 {
  font-size: 2.2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Paragraph text */
p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
}

/* Unordered lists */
ul {
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 20px;
}

/* li {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
} */

/* Links */
a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
