/* .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f9f9f9;
  } */

  h2 {
    margin-bottom: 20px;
  }

  .input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .button {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    background-color: #6953A4;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .button:hover {
    filter: brightness(75%);
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  /* Media queries for responsiveness */
  @media (max-width: 600px) {
    .input, .button {
      font-size: 14px;
      padding: 8px;
    }
  }
